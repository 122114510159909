import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { useIntl } from 'react-intl';

import {
  BUY, DATA_AGGREGATE_BY_METER, DATA_AGGREGATE_BY_PROPERTY,
  DATA_GROUP_BY_COUNTERPARTY, DATA_GROUP_BY_TRADE_TYPE, SELL,
} from 'src/util/constants';
import {
  EXPORTS, IMPORTS,
  SectionContainer, SectionHeader, SectionWrapper,
  UNIT_CURRENCY_FORMAT, UNIT_ENERGY_FORMAT,
  UNIT_CARBON_DEFAULT_LABEL, UNIT_CARBON_TOOLTIP_FORMAT, UNIT_ENERGY_LABEL,
}
  from 'src/enosikit/components/Chart/components/chartConstants';
import {
  getDirectionLabel,
  getMeterChartToolipLabel, formatTooltipData,
} from 'src/enosikit/components/Chart/helpers/tooltip';
import { getTradeTypeLabel } from 'src/util/i18n/helpers';

/**
 * Renders the tooltip content for trade chart
 * @param {boolean} isCarbon
 * @param {object} data - the tooltip datapack.
 * @param {string} yAxisFormat
 * @param {string} unitLabel
 * @param {string} format - the tooltip format.
 * @param {object} chartView - { aggregateBy, groupBy }
 * @param {DATA_AGGREGATE_BY_PROPERTY | DATA_AGGREGATE_BY_METER} chartView.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY | DATA_GROUP_BY_TRADE_TYPE} chartView.groupBy
 * @returns {React.ReactElement | null} - trade tooltip.
 */
export const tradeChartTooltip = (
  isCarbon,
  data,
  yAxisFormat,
  unitLabel,
  format,
  chartView,
) => {
  if (!data || !chartView) {
    return null;
  }
  const intl = useIntl();
  const tradeTooltip = [BUY, SELL].map((direction) => {
    if (!data[direction] || Object.keys(data[direction]).length === 0) {
      return null;
    }

    const tooltipLabel = direction === BUY
      ? getDirectionLabel(IMPORTS, intl) : getDirectionLabel(EXPORTS, intl);
    const { aggregateBy } = chartView;

    const aggByProperty = aggregateBy === DATA_AGGREGATE_BY_PROPERTY;

    return (
      <SectionWrapper key={direction}>
        {!aggByProperty && (
          <SectionHeader>
            {tooltipLabel}
          </SectionHeader>
        )}

        {Object.keys(data[direction]).map((key) => {
          const d = data[direction][key];
          if (!d) {
            return null;
          }
          const {
            label, property, subLabel,
          } = d;

          const tradeTypeLabel = aggByProperty
            ? getTradeTypeLabel(intl, subLabel) : getTradeTypeLabel(intl, label);

          return (
            <Fragment key={key}>
              {(aggByProperty && property?.title) && (
                <SectionHeader>
                  {`${tooltipLabel} - ${property.title}`}
                </SectionHeader>
              )}
              <Row key={key} className="mb-1">
                <Col className="pe-0">
                  <span>{tradeTypeLabel}</span>
                </Col>
                <Col xs="auto">
                  {formatTooltipData(isCarbon, format, yAxisFormat, d.value, unitLabel)}
                </Col>
              </Row>

            </Fragment>
          );
        })}
      </SectionWrapper>
    );
  });
  return tradeTooltip;
};

/**
 * Forms the content for the meter tooltip
 * @param {object} tooltipData
 * @param {string} dirLabel
 * @param {boolean} isCarbon
 * @param {string} tooltipFormat
 * @param {string} yAxisFormat
 * @param {string} unitLabel
 * @returns {React.ReactElement} - meter tooltip content.
 */
const renderMeterViewContent = (
  tooltipData,
  dirLabel,
  isCarbon,
  tooltipFormat,
  yAxisFormat,
  unitLabel,
) => {
  const {
    flags, value, title, identifier,
  } = tooltipData || {};
  return (
    <SectionWrapper>
      <SectionHeader>
        {getMeterChartToolipLabel(dirLabel, { title, identifier })}
      </SectionHeader>
      <div>
        {formatTooltipData(isCarbon, tooltipFormat, yAxisFormat, value, unitLabel)}
      </div>
      {flags?.length > 0 && (
        <SectionContainer>
          {flags.map((el) => el.identifier).join(', ')}
        </SectionContainer>
      )}
    </SectionWrapper>
  );
};

/**
 * Renders the meter tooltip
 * @param {object} tooltipData
 * @param {string} dirLabel
 * @param {boolean} isCarbon
 * @param {string} tooltipFormat
 * @param {string} yAxisFormat
 * @param {string} unitLabel
 * @returns {React.ReactElement} - meter tooltip content.
 */

const getMeterTooltipData = (
  isCarbon,
  tooltipData,
  dirLabel,
  unitLabel,
  yAxisFormat,
  tooltipFormat,
  aggByProperty,
) => {
  if (!aggByProperty) {
    return renderMeterViewContent(
      tooltipData,
      dirLabel,
      isCarbon,
      tooltipFormat,
      yAxisFormat,
      unitLabel,
    );
  }

  const meterTooltipData = Object.keys(tooltipData).map((meterId) => (
    <Fragment key={meterId}>
      {renderMeterViewContent(
        tooltipData[meterId],
        dirLabel,
        isCarbon,
        tooltipFormat,
        yAxisFormat,
        unitLabel,
      )}
    </Fragment>
  ));
  return meterTooltipData;
};

/**
 * Renders the tooltip content for a meter chart.
 * @param {boolean} isCarbon - Indicates whether the chart is for carbon data.
 * @param {object} tooltipData - The data to be displayed in the tooltip.
 * @param {UNIT_ENERGY_FORMAT | UNIT_CURRENCY_FORMAT} yAxisFormat - The format for the
 * y-axis values.
 * @param {UNIT_CARBON_DEFAULT_LABEL | UNIT_ENERGY_LABEL | string} unitLabel - The label for
 * the unit of the data.
 * @param {UNIT_CARBON_TOOLTIP_FORMAT} tooltipFormat - The format for the tooltip values.
 * @param {object} chartView - { aggregateBy, groupBy }
 * @param {DATA_AGGREGATE_BY_PROPERTY | DATA_AGGREGATE_BY_METER} chartView.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY | DATA_GROUP_BY_TRADE_TYPE} chartView.groupBy
 * @returns {React.ReactElement | null} - The rendered tooltip content,
 */
export const meterChartTooltip = (
  isCarbon,
  tooltipData,
  yAxisFormat,
  unitLabel,
  tooltipFormat,
  chartView,
) => {
  if (!tooltipData || !chartView) {
    return null;
  }
  const { tooltipConsumed, tooltipGenerated } = tooltipData;
  if (!tooltipConsumed && !tooltipGenerated) {
    return null;
  }
  const intl = useIntl();
  const { aggregateBy } = chartView;
  const aggByProperty = aggregateBy === DATA_AGGREGATE_BY_PROPERTY;

  const tooltips = [
    {
      key: 'tooltip-consumed',
      tooltip: (tooltipConsumed
        && (getMeterTooltipData(
          isCarbon,
          tooltipConsumed,
          getDirectionLabel(IMPORTS, intl),
          unitLabel,
          yAxisFormat,
          tooltipFormat,
          aggByProperty,
        ))
      ),
    }, {
      key: 'tooltip-generated',
      tooltip: (tooltipGenerated
        && (getMeterTooltipData(
          isCarbon,
          tooltipGenerated,
          getDirectionLabel(EXPORTS, intl),
          unitLabel,
          yAxisFormat,
          tooltipFormat,
          aggByProperty,
        ))),
    },
  ];

  return (
    tooltips.map((tt) => (
      <Fragment key={tt.key}>
        {tt.tooltip}
      </Fragment>
    ))
  );
};
