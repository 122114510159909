import Link from 'found/Link';
import PropTypes from 'prop-types';
import React from 'react';

import {
  Dropdown, DropdownToggle, DropdownMenu,
} from 'reactstrap';

// Fontawesome
import { config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBriefcase, faCheck, faCog, faIndent, faLightbulb,
} from '@fortawesome/free-solid-svg-icons';

import NavigationItem from './NavigationItem';

// Fontawesome CSS needs to be loaded?
config.autoAddCss = true;

/**
 *
 * @param {string} icon
 * @param {string} className
 * @returns {React.ReactElement} - font awesome icon element
 */
function iconFor(icon, className) {
  switch (icon) {
    case 'faBriefcase':
      return <FontAwesomeIcon icon={faBriefcase} className={className} />;
    case 'faCheck':
      return <FontAwesomeIcon icon={faCheck} className={className} />;
    case 'faCog':
      return <FontAwesomeIcon icon={faCog} className={className} />;
    case 'faIndent':
      return <FontAwesomeIcon icon={faIndent} className={className} />;
    case 'faLightbulb':
      return <FontAwesomeIcon icon={faLightbulb} className={className} />;
    default:
      return null;
  }
}

/**
 * @deprecated component not being used
 */
class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      propertiesDropdownOpen: false,
    };
  }

  toggleProperties = () => {
    this.setState((prevState) => ({ propertiesDropdownOpen: !prevState.propertiesDropdownOpen }));
  };

  selectedPropertyGetName = () => {
    const { properties, selectedPropertyId } = this.props;
    let selectedPropertyTitle = '';
    properties.forEach((property) => {
      if (property.id === selectedPropertyId) {
        selectedPropertyTitle = property.title;
      }
    });
    return selectedPropertyTitle;
  };

  selectedPropertyFaCheck = (property) => {
    const { selectedPropertyId } = this.props;
    if (property.id === selectedPropertyId) {
      return iconFor('faCheck', 'ms-2');
    }
    return null;
  };

  render() {
    const { properties, selectedPropertyId } = this.props;
    const { propertiesDropdownOpen } = this.state;

    if (properties.length > 0 && selectedPropertyId) {
      return (
        <>
          <Dropdown nav isOpen={propertiesDropdownOpen} toggle={this.toggleProperties} active>
            <DropdownToggle nav caret>
              {this.selectedPropertyGetName()}
            </DropdownToggle>
            <DropdownMenu>
              {properties.map((property) => (
                <Link to={`/properties/${property.id}`} activeClassName="active" className="dropdown-item" role="menuitem">
                  {property.title}
                  {this.selectedPropertyFaCheck(property)}
                </Link>
              ))}
            </DropdownMenu>
          </Dropdown>
          <NavigationItem text="Trade Rules" path={`/properties/${selectedPropertyId}/trade-rules/active`} icon={iconFor('faBriefcase', '')} />
          <NavigationItem text="Trade History" path={`/properties/${selectedPropertyId}/trade-history`} icon={iconFor('faIndent', '')} />
          <NavigationItem text="Usage" path={`/properties/${selectedPropertyId}/usage`} icon={iconFor('faLightbulb', '')} />
          <NavigationItem text="Settings" path={`/properties/${selectedPropertyId}/settings`} icon={iconFor('faCog', '')} />
        </>
      );
    }
    return (
      <>
        <NavigationItem text="Properties" path="/" />
        <NavigationItem text="Friends" path="/network" />
      </>
    );
  }
}

Navigation.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      active: PropTypes.shape({
        start: PropTypes.string,
        finish: PropTypes.string,
      }),
    }),
  ),
  selectedPropertyId: PropTypes.string,
};

Navigation.defaultProps = {
  properties: [],
  selectedPropertyId: '',
};

export default Navigation;
