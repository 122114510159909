import React from 'react';
import { FormattedMessage } from 'react-intl';
import { UNTRADED_ENERGY_KEY } from 'src/util/constants';
import {
  COLOR_EXPORT, COLOR_IMPORT,
  OPACITY_100, OPACITY_40, OPACITY_10,
  UNIT_CARBON, UNIT_CURRENCY, UNIT_ENERGY,
  carbonProps, currencyProps, energyProps, CARBON_EMISSION,
  GRAM, KILO_GRAM, TONNE, MEGA_TONNE, KILO_TONNE,
  COLOR_IMPORT_UNTRADED, COLOR_EXPORT_UNTRADED,
}
  from './chartConstants';

export const convertInt = (operation, value) => (operation === 'sell' ? -Math.abs(value) : value);

/**
 * Prepares the final data for the trade and meter chart
 * @param {Array} chartdata
 * @param {string} direction - import or export
 * @returns {Array} - chart data (meter and trade).
 */
export const formFinalChartData = (chartdata, direction) => {
  const finalChartData = [];
  if (chartdata) {
    chartdata.forEach((item) => {
      const { x, y } = item || {};
      const chartDataObj = {
        x: x?.ts,
        y: convertInt(direction, y),
        fullDateObj: x,
        label: '',
      };
      finalChartData.push(chartDataObj);
    });
  }

  return finalChartData;
};

export const chartStateOpacity = (key, hoverKeys, selectedKeys) => {
  if (hoverKeys.length === 0 && selectedKeys.length === 0) { return OPACITY_100; }
  if (hoverKeys.includes(key)) { return OPACITY_100; }
  if (selectedKeys.includes(key)) {
    return hoverKeys.length === 0 ? OPACITY_100 : OPACITY_40;
  }
  return OPACITY_10;
};

const getEmptyDataMsg = {
  meter: <FormattedMessage
    id="chart.components.chart_helpers.meter.missing_data"
    defaultMessage="No meter data available for the selected time range."
  />,
  trade: <FormattedMessage
    id="chart.components.chart_helpers.trade.missing_data"
    defaultMessage="No trade data available for the selected time range."
  />,
};

export const showNoDataMsg = (chartType) => (
  <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
    <div className="alert alert-warning">
      {getEmptyDataMsg[chartType]}
    </div>
  </div>
);

/**
 * Define the bar color
 * @param {number} yValue
 * @param {string} key - residual, community ...
 * @returns {string} - bar colour
 */
export const barColor = (yValue, key) => {
  if (!yValue && !key) { return 'none'; }

  const isUntraded = key.includes(UNTRADED_ENERGY_KEY);

  const buyColor = isUntraded ? COLOR_IMPORT_UNTRADED : COLOR_IMPORT;
  const sellColor = isUntraded ? COLOR_EXPORT_UNTRADED : COLOR_EXPORT;

  return (yValue >= 0 ? buyColor : sellColor);
};

// if the x position is half way through the chart, flip the tooltip to the left
export const getTooltipPosition = (x, chartWidth) => {
  if (chartWidth / x < 2) {
    return x - 200;
  }
  return x;
};

export const carbonLabel = () => (
  <>
    CO
    <sub>2</sub>
    &bull;e
  </>
);

export const getUnitProps = (unit) => {
  switch (unit) {
    case UNIT_CARBON:
      return carbonProps;
    case UNIT_CURRENCY:
      return currencyProps;
    case UNIT_ENERGY:
      return energyProps;
    default:
      return energyProps;
  }
};

export const formatCarbonEmission = (value) => {
  if (!value) {
    return null;
  }
  const finalValues = { value };
  let formatLabel = KILO_GRAM;
  if (value < 1.0) {
    formatLabel = GRAM;
    finalValues.value = value * 1000;
  } else if (value >= 1.0 && value < 1000.0) {
    formatLabel = KILO_GRAM;
  } else if (value >= 1000.0 && value < 1000000.0) {
    formatLabel = TONNE;
    finalValues.value = value / 1000;
  } else if (value >= 1000000.0 && value < 1000000000.0) {
    formatLabel = KILO_TONNE;
    finalValues.value = value / 1000000;
  } else {
    formatLabel = MEGA_TONNE;
    finalValues.value = value / 1000000000;
  }
  finalValues.label = `${formatLabel} ${CARBON_EMISSION}`;
  return finalValues;
};
