import Link from 'found/Link';
import PropTypes from 'prop-types';
import React from 'react';
// import styled from 'styled-components';

import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';

// Fontawesome
import { config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

// Fontawesome CSS needs to be loaded?
config.autoAddCss = true;

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - ContactActions component
 */
function ContactActions(props) {
  const { id, options } = props;

  if (options === undefined || options.length === 0) {
    return (
      null
    );
  }

  return (
    <UncontrolledButtonDropdown>
      <DropdownToggle caret color="darken">
        <FontAwesomeIcon icon={faCog} />
      </DropdownToggle>
      <DropdownMenu end>
        {
          options.map((opt) => {
            const { name, path } = opt;
            const pathToOption = `/users/${id}/${path}`; // Example of path to an option? for example path to remove a specific contact.
            return (
              <DropdownItem>
                <Link to={pathToOption}>
                  {name}
                </Link>
              </DropdownItem>
            );
          })
        }
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
}

ContactActions.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
};

ContactActions.defaultProps = {
  options: [],
};

export default ContactActions;
