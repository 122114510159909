import PropTypes from 'prop-types';
import React from 'react';
import {
  Card, CardBody, Row, Col,
} from 'reactstrap';
import { injectIntl } from 'react-intl';

import { UNTRADED_ENERGY_KEY } from 'src/util/constants';
import { avatar, opacity, ring } from '../helpers/cards';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement } - Cards component
 */
function Cards(props) {
  const {
    cardFunc,
    data,
    hoverFunc,
    hoverKeys,
    selectedFunc,
    selectedKeys,
  } = props;

  if (!data || data.length === 0) {
    return null;
  }
  return (
    <Row className="mt-4">
      {
        data.map((datum) => {
          const {
            buy, key, sell,
            tradeType, user,
          } = datum;

          const cardOpacity = opacity(key, hoverKeys, selectedKeys);
          const cardRing = ring(buy, sell, tradeType === UNTRADED_ENERGY_KEY);
          const cardAvatar = avatar(user || null, cardRing, tradeType);
          const cardId = tradeType ? ` trade-${tradeType}` : '';

          return (
            <Col className={`mb-4 cards${cardId}`} sm="6" lg="4" xl="3" key={key}>
              <Card
                className="h-100"
                style={{ opacity: cardOpacity, cursor: 'pointer' }}
                onMouseEnter={() => (hoverFunc(key, true))}
                onMouseLeave={() => (hoverFunc(key, false))}
                onClick={() => (selectedFunc(key))}
              >
                <CardBody>
                  <Row className="flex-nowrap">
                    <Col xs="auto">
                      {cardAvatar}
                    </Col>
                    <Col xs="auto" className="w-75">
                      {cardFunc(datum)}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          );
        })
      }
    </Row>
  );
}

Cards.propTypes = {
  cardFunc: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    buy: PropTypes.shape({
      count: PropTypes.number,
      value: PropTypes.number,
      volume: PropTypes.number,
    }),
    key: PropTypes.string,
    sell: PropTypes.shape({
      count: PropTypes.number,
      value: PropTypes.number,
      volume: PropTypes.number,
    }),
    tradeType: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
      givenName: PropTypes.string,
      familyName: PropTypes.string,
      email: PropTypes.string,
    }),
  })),
  hoverFunc: PropTypes.func,
  hoverKeys: PropTypes.arrayOf(
    PropTypes.string,
  ),
  selectedFunc: PropTypes.func,
  selectedKeys: PropTypes.arrayOf(
    PropTypes.string,
  ),
};

Cards.defaultProps = {
  data: [],
  hoverFunc: null,
  hoverKeys: [],
  selectedFunc: null,
  selectedKeys: [],
};

export default injectIntl(Cards);
