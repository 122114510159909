import { DateTime } from 'luxon';
import Big from 'big.js';

import calculateCarbonData from 'src/lib/carbondata/calculator';
import { EMISSION_FACTORS } from 'src/lib/carbondata/constants';
import { emissionFactorForRegion, emissionFactors } from 'src/lib/carbondata/helpers';
import {
  BUY, BUYER, DIRECTIONS, METER, SELLER, TRADE,
  TRADE_DIRECTIONS_LABEL, TRADE_TYPE_RESIDUAL,
  UNIT_CARBON_EMISSIONS_ACRONYM,
} from 'src/util/constants';
import { getCounterFactualValue } from 'src/util/counterfactual';
import isNumber from 'src/util/math';

import { dateRangeToTimeRange } from 'src/util/time';
import {
  extractData, filterRulesByTradePointId,
  getMemberNodes, getTimeOffset, hasCounterfactual,
} from 'src/util/mainDataBuilder';

/**
 * Calculate units for the JSON data download.
 * @param {Big} carbonEmissionality
 * @returns {number} - units.
 */
const toFixedDecimalUnits = (carbonEmissionality) => Big(
  carbonEmissionality,
).minus(Big(carbonEmissionality).mod(1)).toNumber();

/**
 * Calculate nanos for the JSON data download.
 * @param {Big} carbonEmissionality
 * @returns {number} - nanos.
 */
export const toFixedDecimalNanos = (carbonEmissionality) => Big(
  carbonEmissionality,
).mod(1).times(10 ** 9).toFixed(0);

/**
 * Calculate the value object for the JSON data download.
 * @param {Big} big - carbon emissionality
 * @returns {object} - {units, nanos}.
 */
const toFixedDecimal = (big) => ({
  units: toFixedDecimalUnits(big), nanos: toFixedDecimalNanos(big),
});

/**
 * Builds the user data
 * @param {object} propertyUsers
 * @returns {object} - user schema.
 */
export const getPropertyUsers = (propertyUsers) => {
  if (!propertyUsers) {
    return {};
  }
  const userNodes = propertyUsers.edges?.map((item) => item.node.user);
  const users = {};
  userNodes?.forEach((user) => {
    const { id } = user;
    users[id] = user;
  });
  return users;
};
/**
 * Builds the carbon emissions data for the json (data pack) download.
 * @param {object} property
 * @param {object} dateRange - {start, finish}
 * @returns {object} - carbonEmissionalityData [{'AU-WA': [{start, finish, value, units}]}]
 */
export const buildCarbonEmissions = (property, dateRange) => {
  if (!property) {
    return {};
  }

  const { timezone, publicHolidayRegion } = property;
  if (!timezone || !publicHolidayRegion) {
    return {};
  }

  const timerange = dateRangeToTimeRange(dateRange, timezone);
  const { start: startTime, finish: finishTime } = timerange;

  const validEmissionFactors = emissionFactors(
    EMISSION_FACTORS,
    publicHolidayRegion,
    startTime,
    finishTime,
  );

  const carbonData = {};

  validEmissionFactors.forEach((validEmissionFactor) => {
    const { timeRange: factorTimeRange } = validEmissionFactor;
    const { start: factorStart, finish: factorFinish } = factorTimeRange;
    const carbonEmissionalityForRegion = emissionFactorForRegion(
      validEmissionFactor,
      publicHolidayRegion,
    );
    const { region, value: carbonEmissionalityValue } = carbonEmissionalityForRegion;
    if (!carbonData[region]) {
      carbonData[region] = [];
    }

    carbonData[region].push({
      start: factorStart,
      finish: factorFinish,
      value: toFixedDecimal(carbonEmissionalityValue),
      carbonEmissionalityValue,
      units: UNIT_CARBON_EMISSIONS_ACRONYM,
    });
  });

  return carbonData;
};

/**
 * Get the final timestamp used for generating the time series data
 * @param {string} aggregation
 * @param {number} timestamp
 * @returns {DateTime} timestamp - afer applying the offset
 */
export const getTimeStamp = (aggregation, timestamp) => {
  const timeOffset = getTimeOffset(aggregation);
  const originalTimeStamp = DateTime.fromSeconds(timestamp);
  return DateTime.fromISO(originalTimeStamp).minus(timeOffset);
};

/**
 * Build main data that is to be used in property dashboard
 * @param {object} property
 * @param {object} timeSpan
 * @returns {object} - main data
 */
export const buildMainData = (property, timeSpan) => {
  const {
    address, externalIdentifier, meters: allMeters,
    publicHolidayRegion: propertyRegion, propertyUsers,
    timezone, title,
  } = property;
  // get all meter nodes
  const meterNodes = getMemberNodes(allMeters) || [];
  const extractList = [];

  // data extraction
  if (meterNodes.length > 0) {
    meterNodes.forEach((node) => {
      const dataExtract = extractData(node);
      extractList.push(dataExtract);
    });
  }

  const carbonEmissions = buildCarbonEmissions(property, timeSpan);
  const carbonEmissionality = {
    [propertyRegion]: carbonEmissions[propertyRegion],
  };
  const users = getPropertyUsers(propertyUsers);

  const mainData = {
    property: {
      address,
      carbonEmissionality,
      externalIdentifier,
      region: propertyRegion,
      title,
      timezone,
      users,
    },
    buy: { rules: {}, data: {} },
    sell: { rules: {}, data: {} },
    meters: {},
  };
  let buyTimestamps = [];
  let sellTimestamps = [];

  // form rules and extract timestamp list
  if (extractList.length > 0) {
    extractList.forEach((data) => {
      const {
        rules, tradePointId, buy, sell, meters,
      } = data;
      const { meter: buyMeter } = buy;
      const { meter: sellMeter } = sell;

      const buyTimestampList = buyMeter?.data?.map((datum) => datum?.timestamp) || [];
      const sellTimestampList = sellMeter?.data?.map((datum) => datum?.timestamp) || [];
      buyTimestamps = [...buyTimestamps, ...buyTimestampList];
      sellTimestamps = [...sellTimestamps, ...sellTimestampList];

      mainData.buy.rules = {
        ...mainData.buy.rules,
        ...filterRulesByTradePointId(rules, tradePointId, BUYER),
      };
      mainData.sell.rules = {
        ...mainData.sell.rules,
        ...filterRulesByTradePointId(rules, tradePointId, SELLER),
      };
      mainData.meters = { ...mainData.meters, [meters.id]: meters };
    });
  }

  // timestamps to form the object keys
  const timestamps = {
    buy: [...new Set(buyTimestamps)],
    sell: [...new Set(sellTimestamps)],
  };

  // looping over all the timestamps
  DIRECTIONS.forEach((direction) => {
    timestamps[direction].forEach((timestamp) => {
      extractList.forEach((datum) => {
        const { id: meterId, tradePointId } = datum?.meters || {};
        const { trades, meter } = datum[direction] || {};
        const { data: meterData, aggregation } = meter || {};

        if (!aggregation) return mainData;

        const x = getTimeStamp(aggregation, timestamp);
        const finalTimestamp = x.ts;

        // assigning default values
        const {
          meterDataAggregates: defaultMeter = {},
          tradeSetSummaries: defaultTrades = {},
          untradedDataAggregates: defaultUntraded = {},
        } = mainData[direction].data[finalTimestamp] || {};

        mainData[direction].data[finalTimestamp] = {
          meterDataAggregates: defaultMeter,
          tradeSetSummaries: defaultTrades,
          untradedDataAggregates: defaultUntraded,
        };
        // Add tradeset summary grouped by rule id
        if (trades && trades.length > 0) {
          trades.forEach((trade) => {
            const { key, data: tradeData } = trade || {};

            const { ruleId } = key || {};
            if (ruleId && tradeData) {
              const filteredData = tradeData.filter((d) => d?.range?.finish === timestamp);
              let tradeDatum = {
                value: 0, volume: 0, carbon: 0, counterfactual: NaN, meterId,
              };
              let filteredDatum = filteredData && filteredData[0] ? filteredData[0] : '';
              if (filteredDatum) {
                const { directions: tradeDirections, types: tradeTypes, volume } = filteredDatum;
                const tradeDirection = tradeDirections?.length === 1 ? tradeDirections[0] : '';
                const type = tradeTypes?.length === 1 ? tradeTypes[0] : '';

                filteredDatum = {
                  ...filteredDatum,
                  direction: tradeDirection,
                  type,
                  meterId,
                  tradePointId,
                };
                let carbon = 0;
                if (direction === BUY) {
                  const carbonDataProps = {
                    dataType: TRADE,
                    timestamp: x,
                    energy: isNumber(volume) ? Big(volume) : Big(0),
                    propertyRegion,
                    direction: tradeDirection,
                    type,
                  };
                  carbon = calculateCarbonData(carbonDataProps);
                }
                const filteredRules = mainData[direction].rules;

                const tradeSummaryInput = {
                  direction: TRADE_DIRECTIONS_LABEL[direction],
                  range: filteredDatum.range,
                  tradePointId,
                  volume,
                };
                const counterfactual = type === TRADE_TYPE_RESIDUAL
                  ? NaN : getCounterFactualValue(tradeSummaryInput, Object.values(filteredRules));

                filteredDatum = {
                  ...filteredDatum,
                  carbon: Number(carbon),
                  counterfactual,
                };

                // eslint-disable-next-line no-unused-vars
                const { directions, types, ...finalDatum } = filteredDatum; // remove unused keys
                tradeDatum = finalDatum;
              }
              mainData[direction].data[finalTimestamp].tradeSetSummaries[ruleId] = tradeDatum;
            }
          });
        }

        // add meter data grouped by meter trade point id
        if (meterData && meterId) {
          const filteredData = meterData.filter((d) => d?.timestamp === timestamp) || {};
          let meterDatum = filteredData && filteredData[0] ? filteredData[0] : {};
          const { value } = meterDatum;
          let carbon = 0;

          if (direction === BUY) {
            const carbonDataProps = {
              dataType: METER,
              timestamp: x,
              energy: isNumber(value) ? Big(value) : Big(0),
              propertyRegion,
              direction: TRADE_DIRECTIONS_LABEL[direction],
            };
            carbon = calculateCarbonData(carbonDataProps);
          }
          meterDatum = {
            ...meterDatum,
            carbon: Number(carbon),
          };
          mainData[direction].data[finalTimestamp].meterDataAggregates[meterId] = meterDatum;
        }

        return mainData;
      });
    });

    // Add untraded energy
    const tempData = { ...mainData };
    Object.entries(tempData[direction].data)?.forEach(([timestamp, info]) => {
      const { meterDataAggregates, tradeSetSummaries } = info;
      const energy = Big(0);
      let energyByTradePointId = {};
      const untradedDataBymeter = {};

      Object.keys(meterDataAggregates)?.forEach((meterId) => {
        if (!meterDataAggregates[meterId]) return;

        const meter = meterDataAggregates[meterId];
        const { value } = meter;
        energyByTradePointId = isNumber(value)
          ? { ...energyByTradePointId, [meterId]: energy.plus(value) }
          : { ...energyByTradePointId, [meterId]: energy };
        untradedDataBymeter[meterId] = { volume: energyByTradePointId[meterId] };
      });
      Object.keys(tradeSetSummaries)?.forEach((tradeId) => {
        if (tradeSetSummaries[tradeId]) {
          const { volume, meterId } = tradeSetSummaries[tradeId];
          const meterData = mainData.meters[meterId];
          if (meterData?.aggregation) {
            const tradedEnergyVolume = isNumber(volume) ? volume : 0;
            const untradedEnergyVolume = untradedDataBymeter[meterId].volume;
            if (untradedEnergyVolume > 0) {
              untradedDataBymeter[meterId].volume = untradedEnergyVolume
                .minus(tradedEnergyVolume);
            }
          }
        }
      });
      const allRules = mainData[direction].rules;
      const directionLabel = TRADE_DIRECTIONS_LABEL[direction];
      Object.keys(untradedDataBymeter)?.forEach((id) => {
        const { volume: untradedVolume } = untradedDataBymeter[id];
        const carbonDataProps = {
          dataType: TRADE,
          timestamp,
          energy: Number.isNaN(untradedVolume) ? Big(0) : untradedVolume,
          direction: directionLabel,
          type: TRADE_TYPE_RESIDUAL,
          propertyRegion,
        };
        const currentMeter = mainData.meters[id] || {};
        const untradedValue = getCounterFactualValue({
          direction: directionLabel,
          range: { start: timestamp, finish: timestamp },
          tradePointId: currentMeter.tradePointId,
          volume: untradedVolume,
        }, Object.values(allRules));

        const carbonData = calculateCarbonData(carbonDataProps);
        untradedDataBymeter[id] = {
          ...untradedDataBymeter[id],
          carbon: carbonData,
          value: untradedValue,
        };
      });
      mainData[direction].data[timestamp].untradedDataAggregates = untradedDataBymeter;
    });
  });

  mainData.hasCounterfactual = hasCounterfactual(mainData);

  return mainData;
};
