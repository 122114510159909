import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { normaliseTradeData } from 'src/components/Dashboard/helpers/common';
import Loading from 'src/components/Loading';
import { renderSummaryCards } from 'src/components/Dashboard/helpers/showTradeSummary';

class PropertyShowTradeSummary extends React.Component {
  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    const { mainData } = this.props;

    if (!mainData) {
      return <Loading />;
    }

    const { hasCounterfactual, property } = mainData;
    const { title } = property;

    const summaryData = normaliseTradeData(mainData);

    return (
      <div className="trade-summaries mb-4 row row-cols-1 row-cols-sm-2">
        {renderSummaryCards(summaryData, title, hasCounterfactual)}
      </div>
    );
  }
}

PropertyShowTradeSummary.propTypes = {
  mainData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default PropertyShowTradeSummary;
