import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  Button, ButtonGroup, ButtonToolbar, DropdownItem, DropdownMenu, DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

import { APIConfig } from 'src/config';
import {
  TRADE_RULE_STATE_PROPOSED, TRADE_RULE_STATE_ACCEPTED, TRADE_RULE_STATE_CANCELLED,
  TRADE_RULE_STATE_REJECTED, TRADE_RULE_STATE_CLOSED, TRADE_TYPE_CONTRACTED,
  TRADE_TYPE_NOMINATED, TRADE_TYPE_COMMUNITY, TRADE_TYPE_RESIDUAL, LIST_FILTER_ALL,
  FEATURE_INHIBIT_COMMUNITY_TRADES, FEATURE_INHIBIT_CONTRACTED_TRADES,
  FEATURE_INHIBIT_NOMINATED_TRADES,
} from 'src/util/constants';

/**
 *
 * @param {any} root0
 * @param {any} root0.date
 * @param {any} root0.dateUpdateFunc
 * @param {any} root0.filter
 * @param {any} root0.filterFunc
 * @returns {any} - AdminTradeRuleListControls component.
 */
function AdminTradeRuleListControls({
  date, dateUpdateFunc, filter, filterFunc,
}) {
  const [selectedDate, setSelectedDate] = useState(date);
  const [myRef, setMyRef] = useState(false);

  const closeCalendar = () => {
    myRef.setOpen(false);
  };

  const dateButtonLabel = (d) => (d === null ? '' : d.toFormat('DD'));

  const setDate = () => {
    dateUpdateFunc(selectedDate);
  };

  const updateDate = (d) => {
    const newDate = d && DateTime.fromJSDate(d);
    if (newDate && date && newDate.toSeconds() === date.toSeconds()) {
      return;
    }
    setSelectedDate(newDate);
  };

  const renderDatePicker = (onChangeFunc, onCloseFunc) => (
    <DatePicker
      ref={(r) => { setMyRef(r); }}
      value={dateButtonLabel(date)}
      selected={selectedDate ? selectedDate.toJSDate() : null}
      onChange={onChangeFunc}
      onCalendarClose={onCloseFunc}
      customInput={<DateButton />}
      shouldCloseOnSelect={false}
      popperPlacement="bottom-end"
      popperModifiers={{
        preventOverflow: {
          enabled: true,
          escapeWithReference: false,
          boundariesElement: 'viewport',
        },
      }}
    >
      <div
        style={{
          clear: 'both',
          textAlign: 'right',
          borderTop: '1px solid #ccc',
          padding: '1em',
        }}
      >
        <button className="btn btn-primary" type="button" onClick={() => (closeCalendar())}>Apply</button>
      </div>
    </DatePicker>
  );

  const states = {
    [TRADE_RULE_STATE_PROPOSED]: 'Proposed',
    [TRADE_RULE_STATE_ACCEPTED]: 'Accepted',
    [TRADE_RULE_STATE_CANCELLED]: 'Cancelled',
    [TRADE_RULE_STATE_REJECTED]: 'Rejected',
    [TRADE_RULE_STATE_CLOSED]: 'Closed',
  };

  const types = {
    [TRADE_TYPE_RESIDUAL]: 'Retailer default',
  };
  if (!APIConfig().feature(FEATURE_INHIBIT_CONTRACTED_TRADES)) {
    types[TRADE_TYPE_CONTRACTED] = 'Contracted';
  }
  if (!APIConfig().feature(FEATURE_INHIBIT_NOMINATED_TRADES)) {
    types[TRADE_TYPE_NOMINATED] = 'Peer-to-peer';
  }
  if (!APIConfig().feature(FEATURE_INHIBIT_COMMUNITY_TRADES)) {
    types[TRADE_TYPE_COMMUNITY] = 'Community';
  }

  return (
    <div className="trade-history-filters">
      <ButtonToolbar>
        <ButtonGroup>
          <Button size="sm" className="btn btn-darken" active={!filter.state && !filter.type && !date} onClick={() => filterFunc(null, LIST_FILTER_ALL)}>
            All
          </Button>
        </ButtonGroup>

        <ButtonGroup className="ms-2">
          <UncontrolledButtonDropdown size="sm">
            <DropdownToggle caret className="btn btn-darken" active={!!filter.state}>
              {filter.state ? states[filter.state] : 'State'}
            </DropdownToggle>
            <DropdownMenu>
              {Object.entries(states).map(([value, label]) => (
                <DropdownItem
                  active={filter.state === value}
                  onClick={() => filterFunc('state', value)}
                  key={value}
                >
                  {label}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledButtonDropdown>

          <UncontrolledButtonDropdown size="sm">
            <DropdownToggle caret className="btn btn-darken" active={!!filter.type}>
              {filter.type ? types[filter.type] : 'Type'}
            </DropdownToggle>
            <DropdownMenu>
              {Object.entries(types).map(([value, label]) => (
                <DropdownItem
                  active={filter.type === value}
                  onClick={() => filterFunc('type', value)}
                  key={value}
                >
                  {label}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </ButtonGroup>

        <ButtonGroup className="ms-2">
          {renderDatePicker(updateDate, setDate)}
        </ButtonGroup>
      </ButtonToolbar>
    </div>
  );
}

AdminTradeRuleListControls.propTypes = {
  date: PropTypes.instanceOf(DateTime),
  dateUpdateFunc: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    state: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  filterFunc: PropTypes.func.isRequired,
};

AdminTradeRuleListControls.defaultProps = {
  date: null,
};

// Ref: <https://github.com/Hacker0x01/react-datepicker/issues/862>
//
// eslint-disable-next-line react/prefer-stateless-function
class DateButton extends React.Component {
  render() {
    const {
      onClick,
      disabled,
      value,
    } = this.props;

    return (
      <UncontrolledButtonDropdown size="sm" onClick={() => (onClick())} disabled={disabled}>
        <DropdownToggle caret className="btn btn-darken" active={value !== ''}>
          {value === '' ? (
            <FontAwesomeIcon icon={faCalendar} />
          ) : value}
        </DropdownToggle>
      </UncontrolledButtonDropdown>
    );
  }
}

DateButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.string,
};
DateButton.defaultProps = {
  disabled: false,
  onClick: null,
  value: 'loading...',
};

export default AdminTradeRuleListControls;

export { DateButton };
