import React from 'react';
import { FormattedMessage } from 'react-intl';

import { UNTRADED_ENERGY_KEY } from 'src/util/constants';
import {
  BUY, CARBON_EMISSION, carbonProps, COLOR_EXPORT, COLOR_EXPORT_UNTRADED,
  COLOR_IMPORT, COLOR_IMPORT_UNTRADED, currencyProps,
  energyProps, GRAM, KILO_GRAM, KILO_TONNE,
  MEGA_TONNE, METER, OPACITY_100, OPACITY_40, OPACITY_10,
  SELL, TONNE, TRADE, UNIT_CARBON, UNIT_CURRENCY, UNIT_ENERGY,
}
  from '../components/chartConstants';

/**
 * Prepares the final data for the trade and meter chart
 * @param {Array} chartdata
 * @param {BUY|SELL} direction - buy or sell
 * @returns {Array} - chart data (meter and trade).
 */
export const formFinalChartData = (chartdata, direction) => {
  const finalChartData = [];
  if (chartdata) {
    chartdata.forEach((item) => {
      const { x, y } = item || {};
      const chartDataObj = {
        x: x?.ts,
        y: direction === SELL ? -Math.abs(y) : y,
        fullDateObj: x,
        label: '',
      };
      finalChartData.push(chartDataObj);
    });
  }

  return finalChartData;
};

/**
 * Returns the opacity which is used to higlight or
 * hide the chart's stacks based on user's mouse action
 * @param {Array<string>} key
 * @param {Array<string>} hoverKeys
 * @param {Array<string>} selectedKeys
 * @returns {string} - opacity
 */
export const chartStateOpacity = (key, hoverKeys, selectedKeys) => {
  if (hoverKeys.length === 0 && selectedKeys.length === 0) { return OPACITY_100; }
  if (hoverKeys.includes(key)) { return OPACITY_100; }
  if (selectedKeys.includes(key)) {
    return hoverKeys.length === 0 ? OPACITY_100 : OPACITY_40;
  }
  return OPACITY_10;
};

const getEmptyDataMsg = {
  meter: <FormattedMessage
    id="chart.components.chart_helpers.meter.missing_data"
    defaultMessage="No meter data available for the selected time range."
  />,
  trade: <FormattedMessage
    id="chart.components.chart_helpers.trade.missing_data"
    defaultMessage="No trade data available for the selected time range."
  />,
};

/**
 * Returns the message when there is a no chart data
 * @param {METER|TRADE} chartType
 * @returns {React.ReactElement} - No data message
 */
export const showNoDataMsg = (chartType) => (
  <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
    <div className="alert alert-warning">
      {getEmptyDataMsg[chartType]}
    </div>
  </div>
);

/**
 * Define the bar color
 * @param {number} yValue
 * @param {string} key - residual, community ...
 * @returns {string} - bar colour
 */
export const barColor = (yValue, key) => {
  if (!yValue && !key) { return 'none'; }

  const isUntraded = key === UNTRADED_ENERGY_KEY;

  const buyColor = isUntraded ? COLOR_IMPORT_UNTRADED : COLOR_IMPORT;
  const sellColor = isUntraded ? COLOR_EXPORT_UNTRADED : COLOR_EXPORT;

  return (yValue >= 0 ? buyColor : sellColor);
};

/**
 * Returns the carbon label for the chart
 * @returns {React.ReactElement} - carbon label element
 */
export const carbonLabel = () => (
  <>
    CO
    <sub>2</sub>
    &bull;e
  </>
);

/**
 * Returns the props/attributes for a given unit
 * @param {UNIT_CARBON | UNIT_CURRENCY|UNIT_ENERGY} unit
 * @returns {object} - unit props
 */
export const getUnitProps = (unit) => {
  switch (unit) {
    case UNIT_CARBON:
      return carbonProps;
    case UNIT_CURRENCY:
      return currencyProps;
    case UNIT_ENERGY:
      return energyProps;
    default:
      return energyProps;
  }
};

/**
 * Formats the carbon emission value
 * @param {number} value
 * @returns {object} - formatted carbon emission value and label
 */
export const formatCarbonEmission = (value) => {
  if (!value) {
    return null;
  }
  const finalValues = { value };
  let formatLabel = KILO_GRAM;
  if (value < 1.0) {
    formatLabel = GRAM;
    finalValues.value = value * 1000;
  } else if (value >= 1.0 && value < 1000.0) {
    formatLabel = KILO_GRAM;
  } else if (value >= 1000.0 && value < 1000000.0) {
    formatLabel = TONNE;
    finalValues.value = value / 1000;
  } else if (value >= 1000000.0 && value < 1000000000.0) {
    formatLabel = KILO_TONNE;
    finalValues.value = value / 1000000;
  } else {
    formatLabel = MEGA_TONNE;
    finalValues.value = value / 1000000000;
  }
  finalValues.label = `${formatLabel} ${CARBON_EMISSION}`;
  return finalValues;
};
