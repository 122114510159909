import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Card, CardHeader } from 'reactstrap';

import { AvatarImage, AvatarItem } from '../../Avatar';
import ContactActions from './ContactActions';

const Options = styled.div.attrs({
  className: 'ms-auto',
})``;

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - Contact component
 */
function Contact(props) {
  const {
    avatar, username, email, id, options,
  } = props;

  return (
    <Card className="my-2">
      <CardHeader>
        <div className="d-flex flex-wrap">
          <div className="me-auto">
            <AvatarItem avatarImage={<AvatarImage image={avatar} size="3rem" username={username} />} primaryText={username} secondaryText={email} />
          </div>
          <Options>
            <ContactActions options={options} id={id} />
          </Options>
        </div>
      </CardHeader>
    </Card>
  );
}

Contact.propTypes = {
  avatar: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
};

Contact.defaultProps = {
  options: [],
};

export default Contact;
