import { DateTime, Duration } from 'luxon';
import { APIConfig } from 'src/config';
import {
  P1D, P1M1D, P1M, PT30M,
} from 'src/util/constants';

/**
 * Transform a date implemented as a DateTime in local time, to
 * a timestamp in DateTime in the given preferred timezone.
 * @param {(DateTime|null)} date
 * @param {string} tz name of timezone
 * @returns {(DateTime|null)} the date in the timezone.
 */
export const dateToTimeInTimezone = (date, tz) => {
  if (!date) { return null; }

  const timezone = tz || APIConfig().DEFAULT_TIMEZONE;

  return DateTime.fromObject(
    { year: date.year, month: date.month, day: date.day },
    { zone: timezone },
  );
};

/**
 * For a given date range a finish date is represented as midnight of the day in our date range
 * object, however as a time range it is considered at 24:00:00 that is midnight the following day.
 * A day is a 24 hours interval (not withstanding daylight saving and/or leap seconds). Hence the
 * addition of one day.
 * @param {object} dateRange
 * @param {DateTime} dateRange.start
 * @param {DateTime} dateRange.finish
 * @param {string} tz
 * @returns {object} - time range.
 */
export const dateRangeToTimeRange = (dateRange, tz) => {
  if (!dateRange) return null;

  const timezone = tz || APIConfig().DEFAULT_TIMEZONE;
  const { start: startDate, finish: finishDate } = dateRange;

  const start = dateToTimeInTimezone(startDate, timezone);
  const finish = dateToTimeInTimezone(finishDate, timezone)?.plus({ days: 1 });

  return { start, finish };
};

/**
 * Returns the aggregation for the chart in dashboard (property & portfolio)
 * @param {object} start
 * @param {object} finish
 * @returns {P1D | PT30M} - aggregation
 */
const getAggregation = (start, finish) => {
  let agg = PT30M;
  if (!start || !finish) {
    return agg;
  }
  const duration = finish.diff(start);
  if (duration.as('days') > 7) {
    agg = P1D;
  }
  return agg;
};

/**
 * Returns the time span and aggregation for the dashboard (property & portfolio)
 * @param {object} start
 * @param {object} finish
 * @returns {object} - time span and aggregation
 */
export const getDashboardTimeScope = (start, finish) => {
  let s = DateTime.local().startOf('day').minus(Duration.fromISO(P1M1D));
  if (start) {
    const ts = DateTime.fromSeconds(start);
    if (ts.invalidExplanation === null) {
      s = ts;
    }
  }

  let f = s.plus(Duration.fromISO(P1M));
  if (finish) {
    const ts = DateTime.fromSeconds(finish);
    if (ts.invalidExplanation === null) {
      f = ts.minus(Duration.fromISO(P1D));
    }
  }
  const agg = getAggregation(s, f);

  return { s, f, agg };
};
