import Link from 'found/Link';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  Card, CardBody, CardHeader,
  Form, FormGroup, Label, Input, Button,
  ListGroup, ListGroupItem,
} from 'reactstrap';

import Logo from '../../Logo';

// TODO: figure out how to add style for different widths/points:
// @include devicepoint('767px') {
//   padding: 40px 20px;
// }

// @media only screen and (max-width: 20em) {
//   .login-page {
//     display: block;
//   }

const PasswordResetFrame = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 20em;
`;

const AuthContainer = styled.div`
  min-width: 20em;
  width: 20em;
`;

const Branding = styled.h1`
  margin-bottom: 1em;
  text-align: center;
`;

const CustomHeader = styled(CardHeader)`
  text-align: center;
`;
/**
 * @deprecated component not being used
 */
class PasswordResetRegistration extends React.Component {
  // eslint-disable-next-line class-methods-use-this -- consistency in handling mutations.
  handleSubmit = (e) => {
    e.preventDefault();
  };

  render() {
    const formEmailId = 'email';

    const { loginPath } = this.props;

    return (
      <PasswordResetFrame>
        <AuthContainer>
          <Branding>
            <Logo image="images/branding/enosi-logo.svg" name="Enosi" />
          </Branding>
          <Card>
            <CustomHeader tag="h5">Reset my password</CustomHeader>
            <CardBody>
              <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <Label htmlFor={formEmailId}>Email address</Label>
                  <Input type="email" id={formEmailId} aria-describedby="emailHelp" placeholder="Enter email" />
                </FormGroup>
                <Button type="submit" color="primary">Submit</Button>
              </Form>
            </CardBody>
            <ListGroup flush>
              <ListGroupItem>
                <Link to={loginPath}>
                  Log in to Enosi
                </Link>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </AuthContainer>
      </PasswordResetFrame>
    );
  }
}

PasswordResetRegistration.propTypes = {
  loginPath: PropTypes.string.isRequired,
};

export default PasswordResetRegistration;
