import { DateTime } from 'luxon';
import {
  TRADE_TYPE_COMMUNITY,
  TRADE_TYPE_CONTRACTED,
  TRADE_TYPE_NOMINATED,
  TRADE_TYPE_RESIDUAL,
  UNTRADED_ENERGY_KEY,
} from '../constants';

/**
 * Takes in a timestamp and returns the formatted i18n date string
 * @param {import('react-intl').IntlShape} intl - i18n intl object
 * @param {number} timestamp
 * @returns {string} - date string
 */
//  Note: This assumes the browser local timezone not the property timezone
export const getStringAndNumericFormattedDate = (intl, timestamp) => {
  if (!intl || !timestamp) {
    return null;
  }

  return intl.formatDate(DateTime.fromSeconds(timestamp), {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

/**
 * Takes in a date object and returns the locale based date
 * and time string in the provided timezone.
 * @param {string} locale - date object
 * @param {string} tz - time zone of the property
 * @param {object} timestamp - date object
 * @returns {string} - date and time formatted string
 */
export const getDateAndTimeFormattedString = (locale, tz, timestamp) => {
  if (!timestamp || !locale || !tz) {
    return null;
  }
  return new Intl.DateTimeFormat(locale, {
    dateStyle: 'long',
    timeStyle: 'long',
    timeZone: tz,
  }).format(timestamp);
};

/**
 * Takes in a key and the intl object and returns the translated key
 * for the trade chart
 * @param {import('react-intl').IntlShape} intl - i18n intl object
 * @param {string} key - Community, Residual...
 * @returns {(string | null)} - translated label, or null if not a valid type
 *                              to translate or no intl present.
 */
export const getTradeTypeLabel = (intl, key) => {
  if (!key || !intl) {
    return null;
  }
  switch (key) {
    case TRADE_TYPE_COMMUNITY:
      return intl.formatMessage({ id: 'chart.trade_type.community.label', defaultMessage: 'Community' });

    case TRADE_TYPE_CONTRACTED:
      return intl.formatMessage({ id: 'chart.trade_type.contracted.label', defaultMessage: 'Contracted' });

    case TRADE_TYPE_NOMINATED:
      return intl.formatMessage({ id: 'chart.trade_type.nominated.label', defaultMessage: 'Peer-to-peer' });

    case TRADE_TYPE_RESIDUAL:
      return intl.formatMessage({ id: 'chart.trade_type.residual.label', defaultMessage: 'Retailer default' });

    case UNTRADED_ENERGY_KEY:
      return intl.formatMessage({ id: 'chart.trade_type.untraded.label', defaultMessage: 'Untraded energy' });
    default:
      return key;
  }
};
