import PropTypes from 'prop-types';
import React from 'react';

import Loading from 'src/components/Loading';
import {
  DATA_GROUP_BY_COUNTERPARTY, DATA_AGGREGATE_BY_PORTFOLIO,
  DATA_GROUP_BY_TRADE_TYPE, SOURCE_HISTORIAN, SOURCE_TRADES,
  UNIT_CARBON, UNIT_ENERGY, UNIT_CURRENCY,
  DATA_AGGREGATE_BY_PROPERTY,
} from 'src/util/constants';

import PortfolioShowChart from './PortfolioShowChart';
import PortfolioShowTradeSummary from './PortfolioShowTradeSummary';

/**
 * Renders the PortfolioShowTradeEnergy component, which displays a chart and trade summary
 * for a given portfolio.
 * @param {object} props - The component props.
 * @param {Function} props.controlSetStateFunc - A function to handle change in chart controls.
 * @param {object} props.chartView -grouping and aggregation options for PortfolioShowChart.
 * @param {Function} props.handleTimespanUpdate - A function to handle updates to the timespan.
 * @param {string} props.historianAggregation - Aggregation for the chart.
 * @param {object} props.mainData - The main data to be displayed in the chart and summary.
 * @param {object} props.timespan - An object defining the start and end dates for the timespan.
 * @param {string} props.tradeAggregation - The aggregation method for the trade data.
 * @param {SOURCE_HISTORIAN| SOURCE_TRADES} props.source - The source of the data.
 * @param {UNIT_ENERGY| UNIT_CARBON | UNIT_CURRENCY} props.unit - The unit for the chart.
 * @returns {React.ReactComponentElement} The rendered trade summary section.
 */
function PortfolioShowTradeEnergy(props) {
  if (!props) {
    return <Loading />;
  }

  const {
    chartView, controlSetStateFunc, handleTimespanUpdate,
    historianAggregation, mainData, timespan, tradeAggregation,
    source, unit,
  } = props;

  return (
    <>
      <PortfolioShowChart
        chartView={chartView}
        controlSetStateFunc={controlSetStateFunc}
        mainData={mainData}
        handleTimespanUpdate={handleTimespanUpdate}
        historianAggregation={historianAggregation}
        source={source}
        timespan={timespan}
        tradeAggregation={tradeAggregation}
        unit={unit}
      />
      <PortfolioShowTradeSummary
        mainData={mainData}
      />
    </>
  );
}

PortfolioShowTradeEnergy.propTypes = {
  controlSetStateFunc: PropTypes.func.isRequired,
  chartView: PropTypes.shape({
    groupBy: PropTypes.oneOf([
      DATA_GROUP_BY_COUNTERPARTY,
      DATA_GROUP_BY_TRADE_TYPE]).isRequired,
    aggregateBy: PropTypes.oneOf([
      DATA_AGGREGATE_BY_PORTFOLIO,
      DATA_AGGREGATE_BY_PROPERTY]).isRequired,
  }).isRequired,
  handleTimespanUpdate: PropTypes.func.isRequired,
  historianAggregation: PropTypes.string.isRequired,
  mainData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  timespan: PropTypes.shape({
    start: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    finish: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  source: PropTypes.oneOf([SOURCE_HISTORIAN, SOURCE_TRADES]).isRequired,
  tradeAggregation: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
};

export default PortfolioShowTradeEnergy;
