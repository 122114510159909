import { DateTime } from 'luxon';
import Big from 'big.js';
import {
  CSV_DATA_TIMESTAMP_FORMAT, CSV_FILENAME_TIMESTAMP_FORMAT,
  DIRECTIONS, DATA_PACK, INHIBIT_CARBON_DATA_VIEWS,
  METER, METRICS, MIME_TYPE_CSV, MIME_TYPE_JSON, TRADE,
} from 'src/util/constants';
import {
  UNIT_CARBON_EMISSIONS_ACRONYM,
  UNIT_ENERGY_ACRONYM,
} from 'src/lib/carbondata/constants';
import { APIConfig } from 'src/config';
import csvFileHeaders from 'src/util/helpers';

/**
 * Provides the filename for the downlodable file in property dashboard
 * @param {string} title - property title
 * @param {object} timeRange - time range of the data (meter or trade)
 * e.g {start: DateTime, finish: Datetime}
 * @param {string} type - meter or trade
 * @param {import('react-intl').IntlShape} intl - i18n react-intl
 * @returns {string} - file name (data download).
 */
export const getFileName = (title, timeRange, type, intl) => {
  if (!title && !timeRange) {
    return null;
  }
  const { start, finish } = timeRange;
  const startTime = start.toFormat(CSV_FILENAME_TIMESTAMP_FORMAT);
  const finishTime = finish.toFormat(CSV_FILENAME_TIMESTAMP_FORMAT);
  let fileName;

  switch (type) {
    case 'meter':
      fileName = intl.formatMessage({ id: 'property.property_show.data.meter_data_csv_filename', defaultMessage: 'Enosi Powertracer - {title} - meter data - {startTime} to {finishTime}' }, {
        title, startTime, finishTime,
      });
      break;
    case 'trade':
      fileName = intl.formatMessage({ id: 'property.property_show.data.trade_data_csv_filename', defaultMessage: 'Enosi Powertracer - {title} - trade data - {startTime} to {finishTime}' }, {
        title, startTime, finishTime,
      });
      break;
    default:
      fileName = intl.formatMessage({ id: 'property.property_show.data.json_file_name', defaultMessage: 'Enosi Powertracer - {title} - {startTime} to {finishTime}' }, {
        title, startTime, finishTime,
      });
  }

  return fileName;
};

/**
 * Prepares the user name from the user object
 * concatenates first and last name if available or returns email
 * @param {object} user
 * @returns {string} - user name.
 */
export const getUserName = (user) => {
  if (!user) {
    return null;
  }
  const { familyName, givenName, email } = user;
  let userName = null;
  if (familyName || givenName) {
    userName = `${givenName || ''} ${familyName || ''}`;
  } else {
    userName = email || null;
  }
  return userName?.trim();
};

/**
 * Downloads the csv data (meter or trade) file.
 * @param {Array<object>} data - data that goes in to the file
 * @param {any} name - file name
 * @param {string} type - file typpe
 */
export const downloadHandler = (data, name, type) => {
  const blob = new Blob([data], { type });

  const downloadURL = window.URL.createObjectURL(blob);

  const downloader = document.createElement('a');
  downloader.setAttribute('href', downloadURL);

  const fileName = name || 'Enosi Powertracer.csv';
  downloader.setAttribute('download', fileName);

  downloader.click();
};

/**
 * Returns the time range, with both start and finish timestamps present, for the csv data.
 * @param {number} timestamp - unit timestamp
 * @param {string} aggregation
 * @returns {object} - time range.
 */
export const getTimeRange = (timestamp, aggregation) => {
  if (!timestamp || !aggregation) {
    return { start: null, finish: null };
  }

  if (aggregation === 'P1D') {
    const finish = DateTime.fromSeconds(timestamp).toFormat(CSV_DATA_TIMESTAMP_FORMAT);
    const start = DateTime.fromSeconds(timestamp).minus({ days: 1 })
      .toFormat(CSV_DATA_TIMESTAMP_FORMAT);
    return { start, finish };
  }
  const finish = DateTime.fromSeconds(timestamp).toFormat(CSV_DATA_TIMESTAMP_FORMAT);
  const start = DateTime.fromSeconds(timestamp).minus({ minutes: 30 })
    .toFormat(CSV_DATA_TIMESTAMP_FORMAT);
  return { start, finish };
};
// csv data helpers

/**
 * Prepares the trade parties (buyer and seller) info for a given trade rule id.
 * @param {object} rule - trade rules.
 * @returns {object} - trade parties data
 */
export const getTradePartiesData = (rule) => {
  if (!rule) {
    return null;
  }
  let finalData = null;
  const { buyer, seller } = rule;
  const { tradePoint: buyerTradePoint, user: userBuyer } = buyer || {};
  const { meter: buyerMeter } = buyerTradePoint || {};
  const { tradePoint: sellerTradePoint, user: userSeller } = seller || {};
  const { meter: sellerMeter } = sellerTradePoint || {};
  finalData = {
    buyer: { buyerMeter, buyerName: getUserName(userBuyer) },
    seller: { sellerMeter, sellerName: getUserName(userSeller) },
  };

  return finalData;
};

/**
 * Finds the carbon emissionality based on the given carbonEmissionality array and timestamp.
 * @param {Array} carbonEmissionality - The array of carbon emissionality objects.
 * @param {string} timestamp - The timestamp to compare with emission start and finish times.
 * @returns {object | null} - The carbon emissionality object that matches the
 * timestamp, or null if not found.
 */
export const findCarbonEmissionality = (
  carbonEmissionality,
  timestamp,
) => {
  if (!carbonEmissionality || carbonEmissionality.length === 0 || !timestamp) return null;
  return carbonEmissionality.find((emission) => {
    if (emission.finish) {
      if (emission.start <= DateTime.fromMillis(parseFloat(timestamp))
        && emission.finish >= DateTime.fromMillis(parseFloat(timestamp))) {
        return emission;
      }
    } else if (emission.start <= DateTime.fromMillis(parseFloat(timestamp))) {
      return emission;
    }
    return null;
  });
};

/**
 * Prepares the meter data for download
 * @param {object} mainData
 * @param {import('react-intl').IntlShape} intl - i18n react-intl
 * @returns {object} - meter data {data, name}
 */
export const getMeterDataForDownload = (mainData, intl) => {
  const output = [];
  let carbonEmissionality = Big(0);
  let carbonEmissions = Big(0);
  let fileName = '';

  const { meters = {}, property = {} } = mainData || {};
  const { region, carbonEmissionality: propertyCarbonEmissionality } = property;
  const metersLength = (meters && Object.values(meters)?.length) || 0;
  if (metersLength === 0) {
    return { data: output, fileName: '' };
  }

  Object.values(meters).forEach((meter) => {
    const {
      id: meterId, identifier: meterIdentifier, title: meterTitle, aggregation, timeRange,
    } = meter;
    DIRECTIONS.forEach((dir) => {
      const { data } = mainData[dir] || {};
      if (!data) return;

      if (timeRange && !fileName) {
        fileName = getFileName(
          property.title,
          {
            start: DateTime.fromSeconds(timeRange.start),
            finish: DateTime.fromSeconds(timeRange.finish),
          },
          METER.toLowerCase(),
          intl,
        );
      }

      Object.keys(data).forEach((timestamp) => {
        const datum = mainData?.[dir].data[timestamp];
        const meterDataAggregate = datum.meterDataAggregates[meterId];
        if (!meterDataAggregate) return;

        const dataObj = {
          propertyTitle: property.title,
          billingPointIdentifier: meterIdentifier.split('-')[0],
          title: meterTitle,
          identifier: meterIdentifier,
          metric: METRICS[dir],
          start: getTimeRange(meterDataAggregate.timestamp, aggregation).start,
          finish: getTimeRange(meterDataAggregate.timestamp, aggregation).finish,
          value: meterDataAggregate.value,
          units: UNIT_ENERGY_ACRONYM,
          flags: meterDataAggregate.flags[0].identifier,
        };
        const inhibitCarbonDataViews = APIConfig().feature(INHIBIT_CARBON_DATA_VIEWS);

        if (inhibitCarbonDataViews) {
          output.push(dataObj);
          return;
        }
        carbonEmissionality = findCarbonEmissionality(
          propertyCarbonEmissionality[region],
          timestamp,
        );
        const carbonEmissionValue = carbonEmissionality?.carbonEmissionalityValue || null;
        carbonEmissions = Big(meterDataAggregate.carbon)
          .times(1000); // carbon emissions are in g-CO2•e

        output.push({
          ...dataObj,
          carbonEmissionality: carbonEmissionValue,
          carbonEmissions,
          carbonEmissionsUnits: UNIT_CARBON_EMISSIONS_ACRONYM,
        });
      });
    });

    return output;
  });
  return { data: output, fileName };
};

/**
 * Prepares the trade data for download
 * @param {object} mainData
 * @param {import('react-intl').IntlShape} intl - i18n react-intl
 * @param {object} dateRange - {start, finish}
 * @returns {object} - trade data {data, name}
 */
export const getTradeDataForDownload = (
  mainData,
  intl,
  dateRange,
) => {
  const output = [];
  let carbonEmissionality = Big(0);
  let carbonEmissions = Big(0);
  const { property } = mainData;
  const { region, carbonEmissionality: propertyCarbonEmissionality } = property;
  const { start: startDateRange, finish: finishDateRange } = dateRange;

  const fileName = getFileName(
    property.title,
    {
      start: startDateRange,
      finish: finishDateRange,
    },
    TRADE.toLowerCase(),
    intl,
  );

  DIRECTIONS.forEach((dir) => {
    const { data, rules } = mainData[dir] || {};
    if (!data || !rules) return;

    Object.keys(data).forEach((timestamp) => {
      const { tradeSetSummaries = {} } = mainData[dir].data[timestamp] || {};
      if (!tradeSetSummaries) return;
      Object.keys(tradeSetSummaries)?.forEach((tradeRuleId) => {
        if (tradeRuleId) {
          const datum = mainData?.[dir].data[timestamp];
          const tradeSetSummary = datum.tradeSetSummaries[tradeRuleId];
          if (!tradeSetSummary) return;
          const rule = rules[tradeRuleId];
          const { buyer, seller } = getTradePartiesData(rule);
          const { buyerMeter, buyerName } = buyer || {};
          const { sellerMeter, sellerName } = seller || {};
          const {
            identifier: buyerIdentifier, title: buyerTitle,
            property: buyerProperty,
          } = buyerMeter || {};

          const {
            identifier: sellerIdentifier, title: sellerTitle,
            property: sellerProperty,
          } = sellerMeter || {};

          if (!tradeSetSummary?.type) return;
          const {
            type, averagePrice, value, volume, range,
          } = tradeSetSummary;
          const { start: startTradeRuleRange, finish: finishTradeRuleRange } = range;

          const dataObj = {
            ruleId: tradeRuleId,
            type,
            buyerName,
            buyerPropertyTitle: buyerProperty?.title || null,
            buyerTitle: buyerTitle || null,
            buyerIdentifier: buyerIdentifier || null,
            sellerName,
            sellerPropertyTitle: sellerProperty?.title || null,
            sellerTitle: sellerTitle || null,
            sellerIdentifier: sellerIdentifier || null,
            start: DateTime.fromSeconds(startTradeRuleRange).toFormat(CSV_DATA_TIMESTAMP_FORMAT),
            finish: DateTime.fromSeconds(finishTradeRuleRange).toFormat(CSV_DATA_TIMESTAMP_FORMAT),
            price: averagePrice,
            volume,
            value,
          };
          const inhibitCarbonDataViews = APIConfig().feature(INHIBIT_CARBON_DATA_VIEWS);

          if (inhibitCarbonDataViews) {
            output.push(dataObj);
            return;
          }

          carbonEmissionality = tradeSetSummary.carbonEmissionality;
          carbonEmissionality = findCarbonEmissionality(
            propertyCarbonEmissionality[region],
            timestamp,
          );
          const carbonEmissionValue = carbonEmissionality?.carbonEmissionalityValue || null;
          carbonEmissions = Big(tradeSetSummary.carbon)
            .times(1000); // carbon emissions are in g-CO2•e

          output.push({
            ...dataObj,
            carbonEmissionality: carbonEmissionValue,
            carbonEmissions,
            carbonEmissionsUnits: UNIT_CARBON_EMISSIONS_ACRONYM,
          });
        }
      });
    });
  });

  return { data: output, fileName };
};

/**
 * Sorts the meter data array by meterIdentifier and start date.
 * @param {Array} data - The meter data array to be sorted.
 * @returns {Array} - The sorted meter data array.
 */
export const sortMeterData = (data) => data.sort((a, b) => {
  // Sorting by meterIdentifier
  if (a.identifier < b.identifier) return -1;
  if (a.identifier > b.identifier) return 1;

  // If meterIdentifier is the same, sort chronologically
  if (a.start < b.start) return -1;
  if (a.start > b.start) return 1;

  return 0;
});

/**
 * Sorts the trade data array by meterIdentifier, chronologically, trade type, trade rule id.
 * @param {Array} data - The trade data array to be sorted.
 * @returns {Array} - The sorted trade data array.
 */
export const sortTradeData = (data) => data.sort((a, b) => {
  // Sorting by meterIdentifier
  const aIdentifier = a.buyerIdentifier || a.sellerIdentifier;
  const bIdentifier = b.buyerIdentifier || b.sellerIdentifier;
  if (aIdentifier < bIdentifier) return -1;
  if (aIdentifier > bIdentifier) return 1;

  // If meterIdentifier is the same, sort chronologically
  if (a.start < b.start) return -1;
  if (a.start > b.start) return 1;

  // If chronologically the same, sort by trade type (residual, community, nominated, contracted)
  if (a.type < b.type) return -1;
  if (a.type > b.type) return 1;

  // If trade type is the same, sort by trade rule id
  if (a.ruleId < b.ruleId) return -1;
  if (a.ruleId > b.ruleId) return 1;

  return 0;
});

/**
 * Builds CSV data based on the provided data type and data for download.
 * @param {object} dataInput
 * @param {Function} dataDownloader
 * @param {Function} dataSorter
 * @returns {object} - data for the csv file and file name
 */
export const buildCSVData = (dataInput, dataDownloader, dataSorter) => {
  const { mainData, dateRange, intl } = dataInput;
  const { data, fileName } = dataDownloader(mainData, intl, dateRange);
  dataSorter(data);
  return { data, fileName };
};
/**
 * Prepares the data object that gets feed in to the csv for download
 * Data schema for meter - https://enosi.atlassian.net/wiki/spaces/PT/pages/1838743569/CSV+meter+data+download
 * Data schema for trade - https://enosi.atlassian.net/wiki/spaces/PT/pages/1838710828/CSV+trade+history+data+download
 * @param {string} dataType - meter or trade
 * @param {object} mainData
 * @param {object} dateRange - {start, finish}
 * @param {import('react-intl').IntlShape} intl - i18n react-intl
 * @returns {object} - data for the csv and file names
 */
export const processCSVData = (dataType, mainData, dateRange, intl) => {
  if (!mainData || !dataType) {
    return null;
  }

  const dataInput = { mainData, dateRange, intl };
  const finalResp = dataType === METER
    ? buildCSVData(dataInput, getMeterDataForDownload, sortMeterData)
    : buildCSVData(dataInput, getTradeDataForDownload, sortTradeData);

  return finalResp;
};

/**
 * Prepares CSV data for download
 * @param {Array<object>} data - raw meter or trade data
 * @param {Array<string>} headers - csv file headers
 * @returns {string} - csv data
 */
export const prepareCSVData = (data, headers) => {
  const csvRows = [];
  csvRows.push(headers.join(','));

  data.forEach((item) => {
    const values = Object.values(item).join(',');
    csvRows.push(values);
  });

  return csvRows.join('\n');
};

// json data pack helpers

/**
 * Builds the meter data for the json (data pack) download.
 * @param {object} mainData
 * @returns {object} - meter schema.
 */
export const buildMeterSchema = (mainData) => {
  const finalResp = {};
  if (!mainData) {
    return finalResp;
  }

  const {
    meters, property,
  } = mainData;
  Object.values(meters).forEach((meter) => {
    const {
      aggregation, id, identifier, timeRange,
    } = meter;
    if (id && identifier) {
      finalResp[identifier] = {};
      if (aggregation && timeRange) {
        DIRECTIONS.forEach((dir) => {
          const metricLabel = METRICS[dir];
          const { finish, start } = timeRange;
          finalResp[identifier][metricLabel] = {
            aggregation,
            metric: { identifier: metricLabel },
            timeRange: { finish, start },
            timeZone: property.timezone,
          };
          const dataList = [];
          Object.keys(mainData[dir].data).forEach((timestamp) => {
            const { meterDataAggregates: meterData } = mainData[dir].data[timestamp];
            dataList.push(meterData[id]);
          });
          finalResp[identifier][metricLabel].data = dataList;
        });
      }
    }
  });
  return finalResp;
};

/**
 * Builds the trade data for the json (data pack) download.
 * @param {object} mainData
 * @returns {object} - trade schema
 */
export const buildTradeSchema = (mainData) => {
  const finalResp = {};
  if (!mainData) {
    return finalResp;
  }
  const { meters } = mainData;
  const aggregation = Object.values(meters).map((meter) => meter.aggregation).filter(Boolean);

  DIRECTIONS.forEach((dir) => {
    const { data } = mainData[dir];
    Object.keys(data).forEach((timestamp) => {
      const { tradeSetSummaries: tradeData } = data[timestamp];
      Object.keys(tradeData).forEach((tradeId) => {
        const { tradePointId } = tradeData[tradeId];
        if (tradePointId) {
          const {
            averagePrice,
            buyerTradePointIds,
            direction, type, range, sellerTradePointIds, value, volume,
          } = tradeData[tradeId];
          const tradeObj = {
            buyerTradePointId: buyerTradePointIds && buyerTradePointIds[0],
            direction,
            interval: aggregation && aggregation[0],
            price: { units: averagePrice },
            ruleId: tradeId,
            sellerTradePointId: sellerTradePointIds && sellerTradePointIds[0],
            timeRange: range,
            type,
            value: { units: value },
            volume: { units: volume },

          };
          if (finalResp[tradePointId]) {
            finalResp[tradePointId].push(tradeObj);
            return;
          }
          finalResp[tradePointId] = [tradeObj];
        }
      });
    });
  });
  return finalResp;
};

/**
 * Prepares the schema for json(data pack) download
 * Data schema for data pack - https://enosi.atlassian.net/wiki/spaces/PT/pages/1838743595/JSON+property+data+download
 * @param {object} mainData - property data
 * @param {object} dateRange - {start, finish}
 * @param {import('react-intl').IntlShape} intl - i18n react-intl
 * @returns {object} - json data schema and file name
 */

export const processDataPack = (mainData, dateRange, intl) => {
  if (!mainData || !dateRange || !intl) {
    return null;
  }

  const {
    property, meters, buy, sell,
  } = mainData;
  const { start, finish } = dateRange;
  const fileName = getFileName(property.title, { start, finish }, '', intl);

  const buyRules = buy?.rules;
  const sellRules = sell?.rules;
  const rules = { ...buyRules, ...sellRules };
  const {
    address, carbonEmissionality: carbon, externalIdentifier,
    region, title, users,
  } = property;

  const meterData = buildMeterSchema(mainData);
  const tradeData = buildTradeSchema(mainData);
  const data = {
    address,
    externalIdentifier,
    meterData,
    meters: Object.values(meters),
    title,
    tradeData,
    tradeRuleMap: rules,
    users,
  };

  const inhibitCarbonDataViews = APIConfig().feature(INHIBIT_CARBON_DATA_VIEWS);

  if (inhibitCarbonDataViews) {
    return { data, fileName };
  }

  const carbonEmissionality = {
    [region]: carbon[region].map(
      // eslint-disable-next-line no-unused-vars
      ({ carbonEmissionalityValue, ...attrs }) => attrs,
    ),
  };

  return { data: { ...data, ...{ carbonEmissionalityData: carbonEmissionality } }, fileName };
};

/**
 * Initiate csv or json data download
 * Data schema for meter - https://enosi.atlassian.net/wiki/spaces/PT/pages/1838743569/CSV+meter+data+download
 * Data schema for trade - https://enosi.atlassian.net/wiki/spaces/PT/pages/1838710828/CSV+trade+history+data+download
 * Data schema for data pack - https://enosi.atlassian.net/wiki/spaces/PT/pages/1838743595/JSON+property+data+download
 * @param {string} type - meter, trade or data pack
 * @param {object} mainData
 * @param {object} dateRange - {start, finish}
 * @param {DateTime} [dateRange.start]
 * @param {DateTime} [dateRange.finish]
 * @param {import('react-intl').IntlShape} intl - i18n react-intl
 */
export const downloadManager = (type, mainData, dateRange, intl) => {
  if (!mainData) {
    return;
  }
  if (type === DATA_PACK) {
    const { data: jsonData, fileName } = processDataPack(mainData, dateRange, intl);
    if (jsonData && JSON.stringify(jsonData)) {
      downloadHandler(JSON.stringify(jsonData), fileName, MIME_TYPE_JSON);
    }
    return;
  }

  const inhibitCarbonDataViews = APIConfig().feature(INHIBIT_CARBON_DATA_VIEWS);
  const { data: finalCSVData, fileName } = processCSVData(
    type,
    mainData,
    dateRange,
    intl,
  ) || {};

  const headers = csvFileHeaders(intl, type, inhibitCarbonDataViews);

  if (finalCSVData) {
    const csvdata = prepareCSVData(finalCSVData, headers);
    if (csvdata) {
      const finalFileName = `${fileName}.csv`;
      downloadHandler(csvdata, finalFileName, MIME_TYPE_CSV);
    }
  }
};
