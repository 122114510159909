import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Contact from './Contact';

const ContactContainer = styled.div.attrs({
  className: 'row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4',
})``;

const ContactColumn = styled.div.attrs({
  className: 'col',
})``;

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - ContactActions component
 */
function ContactList(props) {
  const { users } = props;

  return (
    <ContactContainer>
      {users.map((user) => (
        <ContactColumn>
          <Contact
            avatar={user.avatar}
            username={user.username}
            email={user.email}
            id={user.id}
            options={[
              { name: 'Propose trade', path: '/propose' },
              { name: 'Trade Rules', path: '/trade-rules' },
              { name: 'Remove contact', path: '/remove' },
            ]}
          />
        </ContactColumn>
      ))}
    </ContactContainer>
  );
}

ContactList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      username: PropTypes.string,
      email: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
};

ContactList.defaultProps = {
  users: [],
};

export default ContactList;
