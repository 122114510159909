import { DateTime, Duration } from 'luxon';

import {
  DIRECTIONS,
  TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL,
  TRADE_TYPE_RESIDUAL,
  TRADE_RULE_STATE_ACCEPTED, TRADE_RULE_STATE_CLOSED,
} from 'src/util/constants';
import { isTradeRuleFlat } from 'src/util/helpers';

/**
 * Filters trade rules by given trade point id.
 * @param {Array<object>} rules as provided from the graphql response.
 * @param {string} tradePointId
 * @param {string} trader - buyer or seller
 * @returns {object} - rules grouped by tradepoint id
 */
export const filterRulesByTradePointId = (rules, tradePointId, trader) => {
  if (!rules || !tradePointId) {
    return [];
  }
  const filteredRules = Object.values(rules).filter((
    rule,
  ) => rule[trader]?.tradePoint?.id === tradePointId) || [];
  const finalRes = {};
  filteredRules?.forEach((rule) => { finalRes[rule.id] = rule; });
  return finalRes;
};

/**
 * Determine if the main data has counterfactual data available.
 * @param {*} mainData
 * @returns {boolean} flag to indicate counterfactuals exist.
 */
export const hasCounterfactual = (mainData) => {
  // const rules = mainData[direction]?.rules;
  const hasFlatResidualTradeRules = DIRECTIONS.every(
    (direction) => Object.values(mainData[direction].rules)?.length > 0
      && Object.values(mainData[direction].rules)?.every(
        (rule) => rule && (rule.tradeType !== TRADE_TYPE_RESIDUAL || isTradeRuleFlat(rule)),
      ),
  );

  return hasFlatResidualTradeRules;
};

/**
 * The time offset from the ISO8601 string.
 *
 * When we are dealing with day or monthly charts we are dealing with a date and not a timestamp.
 * As we are using the trailing edge of the the period in use, `24:00:00` is rendered as `00:00:00`
 * on the following day. Hence we need to offset that aggregation period before building the primary
 * data.
 * @param {string} aggregation is an ISO8601 duration string, or empty.
 * @returns {Duration | 0 } - time offset.
 */
export const getTimeOffset = (aggregation) => (aggregation.match(/^PT\d+[HMS]/) ? 0 : Duration.fromISO(aggregation));

/**
 * Get the final timestamp used for generating the time series data
 * @param {string} aggregation
 * @param {number} timestamp
 * @returns {DateTime} timestamp - afer applying the offset
 */
export const getTimeStamp = (aggregation, timestamp) => {
  const timeOffset = getTimeOffset(aggregation);
  const originalTimeStamp = DateTime.fromSeconds(timestamp);
  return DateTime.fromISO(originalTimeStamp).minus(timeOffset);
};

/**
 * Get all meter nodes
 * @param {object} member - This can be a meter/property/user
 * @returns {Array<object>} - array of meter nodes
 */
export const getMemberNodes = (member) => member?.edges?.map((edge) => edge.node) || [];

/**
 * Filter trades by trade direction
 * @param {Array<object>} allTrades
 * @param {TRADE_DIRECTION_BUY | TRADE_DIRECTION_SELL} tradeDirection
 * @returns {Array<object>} - filtered trades
 */
export const filterTradesByDirection = (allTrades, tradeDirection) => {
  if (!allTrades || !tradeDirection) {
    return [];
  }
  return allTrades.filter((
    trade,
  ) => trade.key.direction === tradeDirection);
};

/**
 * Filter rules to include only active and closed (that is, previously active) trade rules.
 * @param {Array<object>} allRules
 * @returns {Array<object>} - filtered rules (only active and closed)
 */
export const getAcceptedAndClosedRules = (allRules) => allRules.filter((
  rule,
) => [TRADE_RULE_STATE_ACCEPTED, TRADE_RULE_STATE_CLOSED]
  .indexOf(rule.state) >= 0);

export const getTradeRulesMap = (rules) => {
  const finalRes = {};
  rules?.forEach((rule) => { finalRes[rule.id] = rule; });
  return finalRes;
};
/**
 * Extract main data from the graphql response
 * @param {object} meterNode
 * @returns {object} - response object of trade, meter and trade rules for a given meter
 */
export const extractData = (meterNode) => {
  const {
    active, dataConsumed, dataGenerated, externalIdentifier, id, identifier,
    property, rules, title, tradePointId, tradeSetSummary,
  } = meterNode;
  const aggregation = dataConsumed?.aggregation || dataGenerated?.aggregation;
  const timeRange = dataConsumed?.timeRange || dataGenerated?.timeRange;

  const finalResp = {
    buy: {
      trades: filterTradesByDirection(tradeSetSummary, TRADE_DIRECTION_BUY),
      meter: dataConsumed,
    },
    sell: {
      trades: filterTradesByDirection(tradeSetSummary, TRADE_DIRECTION_SELL),
      meter: dataGenerated,
    },
    meters: {
      active,
      aggregation,
      externalIdentifier,
      id,
      identifier,
      property,
      title,
      tradePointId,
      timeRange,
    },
    rules: rules?.edges ? getAcceptedAndClosedRules(rules.edges.map((r) => r.node)) : [],
    tradePointId,
  };
  finalResp.meters.aggregation = aggregation || '';
  finalResp.meters.timeRange = timeRange || {};

  return finalResp;
};
