import React from 'react';
import PropTypes from 'prop-types';
import {
  VictoryChart, VictoryBar, VictoryAxis,
  VictoryStack, VictoryTooltip, VictoryVoronoiContainer, VictoryLabel,
} from 'victory';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { useIntl } from 'react-intl';
import { getChartLocale } from 'src/util/i18n/constants';
import { scaleDiscontinuous } from 'd3fc-discontinuous-scale';
import { scaleTime } from 'd3-scale';
import { timeFormatDefaultLocale } from 'd3-time-format';
import { formatDefaultLocale } from 'd3-format';
import {
  DATA_AGGREGATE_BY_METER, DATA_AGGREGATE_BY_PORTFOLIO, DATA_AGGREGATE_BY_PROPERTY,
  DATA_GROUP_BY_COUNTERPARTY, DATA_GROUP_BY_TRADE_TYPE,
} from 'src/util/constants';
import ChartTooltip from './ChartTooltip';
import {
  barColor, chartStateOpacity, formFinalChartData,
} from '../helpers/common';
import {
  BUY, ChartAxisStyle, chartPadding, SELL,
  BAR_RATIO, CHART_HEIGHT, TICK_COUNT, Y_AXIS_DOMAIN_MULTIPLIER,
} from './chartConstants';

const ChartWrapper = styled.div`
  svg {
    overflow: visible;
    position: relative;
    z-index: 999;
  }
`;

/**
 * Build and returns victory bar component for the trade and meter chart
 * @param {object} domain
 * @param {object} chartData
 * @param {string} chartOpacity
 * @param {string} key - residual, community ...
 * @param {Function} tooltipUpdate
 * @param {Function} handleChartClick
 * @returns {React.ReactElement} - bar charts.
 */
const chartBars = (domain, chartData, chartOpacity, key, tooltipUpdate, handleChartClick) => (
  <VictoryBar
    barRatio={BAR_RATIO}
    data={chartData}
    domain={domain}
    style={{
      data: {
        fill: ({ datum }) => (barColor(datum.y, key)), opacity: chartOpacity,
      },
    }}
    events={[
      {
        target: 'data',
        eventHandlers: {
          onMouseOver: (devent, datum) => {
            tooltipUpdate(datum.datum.fullDateObj);
          },
          onClick: () => {
            handleChartClick();
          },
        },
      },
    ]}
  />
);

/**
 * Build and returns trade and bar chart
 * @param {object} domain
 * @param {object} data - trade or meter data
 * @param {Function} compareX
 * @param {Function} tooltipUpdate
 * @param {Function} handleChartClick
 * @param {Array} hoverKeys
 * @param {Array} selectedKeys
 * @returns {React.ReactElement} - bar chart stacks.
 */
const chartStacks = (
  domain,
  data,
  compareX,
  tooltipUpdate,
  handleChartClick,
  hoverKeys = [],
  selectedKeys = [],
) => (
  <VictoryStack>
    {[SELL, BUY].map((direction) => {
      const rawChartData = direction === SELL
        ? Object.keys(data[direction]).reverse() : Object.keys(data[direction]);

      const series = rawChartData.map((key) => {
        const directionData = data[direction][key];
        if (directionData && Object.keys(directionData).length > 0) {
          const finalChartData = formFinalChartData(
            Object.values(directionData).sort(compareX),
            direction,
          );
          const chartOpacity = chartStateOpacity(key, hoverKeys, selectedKeys);
          return (
            chartBars(domain, finalChartData, chartOpacity, key, tooltipUpdate, handleChartClick)
          );
        }
        return null;
      });
      return direction === BUY ? series.reverse() : series;
    })}
  </VictoryStack>
);

/**
 * Because this is a stacked set of values we need to aggregate by date then
 * determine the maximum / minimum.
 * @param {object} rawChartData
 * @param {object} domain
 * @returns {object} domain updated based on the raw chart data.
 */
export const processDomainByDataset = (rawChartData, domain) => {
  const updatedDomain = domain;
  const yValueAggregates = {};

  [SELL, BUY].forEach((dir) => {
    const datasets = rawChartData[dir];
    const yMultiplier = dir === SELL ? -1 : 1;

    yValueAggregates[dir] ||= {};

    Object.keys(datasets).forEach((id) => {
      const dataset = datasets[id];
      Object.keys(dataset).forEach((timestamp) => {
        const datum = dataset[timestamp] || {};
        if (datum) {
          updatedDomain.x ||= [datum.x, datum.x];

          if (updatedDomain.x[0] > datum.x) {
            updatedDomain.x[0] = datum.x;
          } else if (updatedDomain.x[1] < datum.x) {
            updatedDomain.x[1] = datum.x;
          }

          const yValue = yMultiplier * datum.y;

          yValueAggregates[dir][timestamp] ||= 0;
          yValueAggregates[dir][timestamp] += yValue;
        }
      });
    });
  });

  const yMin = Math.min(...Object.values(yValueAggregates[SELL]), 0);
  const yMax = Math.max(...Object.values(yValueAggregates[BUY]), 0);

  updatedDomain.y = [yMin, yMax];

  return updatedDomain;
};

/**
 * @param {object} rawChartData
 * @param {string} stepSize
 * @returns {object} domain object.
 */
const chartDomain = (rawChartData, stepSize) => {
  let xAxisDomainPadding = 0;
  switch (stepSize) {
    case 'P1D':
      xAxisDomainPadding = 24 * 60 * 60 * 1000;
      break;
    case 'PT30M':
      xAxisDomainPadding = 30 * 60 * 1000;
      break;
    default:
      throw new Error(`Error: '${stepSize}' invalid`);
  }

  const defaultDomain = { x: null, y: null };

  const domain = processDomainByDataset(rawChartData, defaultDomain);

  // Set the defaults if needed.
  if (domain.x === null || domain.y === null) {
    return { x: [0, 1], y: [0, 1] };
  }

  if (domain.y[0] > 0) { domain.y[0] = 0; }
  if (domain.y[1] < 0) { domain.y[1] = 0; }

  // Pad it out based on the bar chart step size.
  domain.x[0] -= xAxisDomainPadding / 2;
  domain.x[1] += xAxisDomainPadding / 2;
  // Pad out the y-values where they're above/below.
  if (domain.y[0] < 0) { domain.y[0] *= Y_AXIS_DOMAIN_MULTIPLIER; }
  if (domain.y[1] > 0) { domain.y[1] *= Y_AXIS_DOMAIN_MULTIPLIER; }

  return domain;
};

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - StackedBarChart component
 */
export default function StackedBarChart(props) {
  const {
    chartView, stackBarProps, stepSize, toolTipProps, width, yAxisFormat,
  } = props;

  const {
    data,
    hoverKeys,
    selectedKeys,
    compareX,
    tooltipUpdate,
    handleChartClick,
    chartType,
  } = stackBarProps;

  const {
    multipleMeter, unitLabel, tooltipDateFormat, tooltipData, tooltipTimestamp,
    tooltipFormat, tooltipLabelFunc, isCarbon,
  } = toolTipProps;

  const intl = useIntl();
  let formatter = yAxisFormat;
  const chartLocale = getChartLocale(intl.formatMessage);
  if (chartLocale && typeof (chartLocale) === 'object') {
    timeFormatDefaultLocale(chartLocale);
    const timeObj = formatDefaultLocale(chartLocale);
    formatter = timeObj.format(yAxisFormat);
  }
  const domain = chartDomain(data, stepSize);

  const discontinuousScale = scaleDiscontinuous(
    scaleTime(),
  ).domain(domain);

  return (
    <ChartWrapper>
      <VictoryChart
        scale={{ x: discontinuousScale, y: 'linear' }}
        domain={domain}
        height={CHART_HEIGHT}
        width={width}
        padding={chartPadding}
        containerComponent={(
          <VictoryVoronoiContainer
            labels={() => ' '}
            labelComponent={(
              <VictoryTooltip
                flyoutComponent={(
                  <ChartTooltip
                    chartType={chartType}
                    chartView={chartView}
                    chartWidth={width}
                    isCarbon={isCarbon}
                    multipleMeter={multipleMeter}
                    tooltipDateFormat={tooltipDateFormat}
                    tooltipData={tooltipData}
                    tooltipFormat={tooltipFormat}
                    tooltipLabelFunc={tooltipLabelFunc}
                    tooltipTimestamp={tooltipTimestamp}
                    tooltipUpdate={tooltipUpdate}
                    unitLabel={unitLabel}
                    yAxisFormat={yAxisFormat}
                  />
                )}
              />
            )}
          />
        )}
      >
        {
          chartStacks(
            domain,
            data,
            compareX,
            tooltipUpdate,
            handleChartClick,
            hoverKeys,
            selectedKeys,
          )
        }

        <VictoryAxis
          tickCount={TICK_COUNT}
          offsetY={20}
          style={ChartAxisStyle}
          fixLabelOverlap
        />
        <VictoryAxis
          label={unitLabel}
          axisLabelComponent={<VictoryLabel dx={0} dy={-15} />}
          style={ChartAxisStyle}
          crossAxis={false}
          orientation="left"
          dependentAxis
          fixLabelOverlap
          tickFormat={(t) => formatter(t)}
        />
      </VictoryChart>

    </ChartWrapper>
  );
}

StackedBarChart.propTypes = {
  chartView: PropTypes.shape({
    groupBy: PropTypes.oneOf([
      DATA_GROUP_BY_COUNTERPARTY,
      DATA_GROUP_BY_TRADE_TYPE,
    ]).isRequired,
    aggregateBy: PropTypes.oneOf([
      DATA_AGGREGATE_BY_METER,
      DATA_AGGREGATE_BY_PORTFOLIO,
      DATA_AGGREGATE_BY_PROPERTY,

    ]).isRequired,
  }).isRequired,
  stackBarProps: PropTypes.shape({
    data: PropTypes.oneOfType([PropTypes.object]),
    hoverKeys: PropTypes.oneOfType([PropTypes.array]),
    selectedKeys: PropTypes.oneOfType([PropTypes.array]),
    compareX: PropTypes.func,
    tooltipUpdate: PropTypes.func,
    handleChartClick: PropTypes.func,
    chartType: PropTypes.string,
  }),
  stepSize: PropTypes.oneOf(['P1D', 'PT30M']),
  toolTipProps: PropTypes.shape({
    unitLabel: PropTypes.string,
    multipleMeter: PropTypes.bool.isRequired,
    tooltipDateFormat: PropTypes.oneOfType([PropTypes.object]),
    tooltipData: PropTypes.oneOfType([PropTypes.object]),
    tooltipLabelFunc: PropTypes.func,
    tooltipTimestamp: PropTypes.instanceOf(DateTime),
    tooltipFormat: PropTypes.string,
    isCarbon: PropTypes.bool.isRequired,
  }),
  width: PropTypes.number.isRequired,
  yAxisFormat: PropTypes.string.isRequired,
};
StackedBarChart.defaultProps = {
  stackBarProps: null,
  toolTipProps: null,
  stepSize: 'P1D',
};
