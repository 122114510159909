import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import FlashesStore from 'src/stores/FlashesStore';
import { APIConfig } from 'src/config';
import {
  INHIBIT_CARBON_DATA_VIEWS, SOURCE_HISTORIAN,
  UNIT_ENERGY, UNIT, UNIT_CURRENCY, UNIT_CARBON,
} from 'src/util/constants';
import { carbonLabel } from 'src/enosikit/components/Chart/components/ChartHelpers';

/**
 * Provides the input for the chart display (carbon/currency/wh) type selector
 * @param {string} unit
 * @param {string} source
 * @returns {Array<object>} - chart display control options.
 */
export const controlOptionFunc = (unit, source) => {
  const inhibitCarbonDataViews = APIConfig().feature(INHIBIT_CARBON_DATA_VIEWS);
  const unitOps = {
    group: UNIT,
    items: [{
      value: UNIT_ENERGY, label: 'Wh', active: (unit === UNIT_ENERGY), disabled: false,
    }, {
      value: UNIT_CURRENCY, label: <FormattedMessage id="dashboard_control.helpers.common.type_selector.currency.label" defaultMessage="$" />, active: (unit === UNIT_CURRENCY), disabled: (source === SOURCE_HISTORIAN),
    }],
  };
  if (inhibitCarbonDataViews) {
    return [unitOps];
  }

  const carbonController = {
    value: UNIT_CARBON, label: carbonLabel(), active: (unit === UNIT_CARBON),
  };
  unitOps.items.push(carbonController);
  return [unitOps];
};

/**
 * @constant
 * @type {number}
 * @default
 */
export const WARNING_TIMEOUT = 5000;

/**
 * @param {import('react-intl').IntlShape} intl - i18n
 * @returns {React.ReactComponentElement} - warning dialog fragment
 */
export const warningDialog = (intl) => {
  const msgWarning = intl.formatMessage({
    id: 'helpers.dashboard.trade_rules.message_warning',
    defaultMessage: 'Hi! Are you trying to view your trade rules? Create and manage them by selecting Trade Rules in the menu above.',
  });
  return (
    <>
      <FontAwesomeIcon className="me-1" size="lg" icon={faTriangleExclamation} />
      {msgWarning}
    </>
  );
};

/**
 * Creates the warning dialog when the user in dashboard trades view
 * clicks on trades toggle button more than once in 5s
 * @param {import('react-intl').IntlShape} intl - i18n
 * @returns {void}
 */
export const showTradeRuleMsg = (intl) => {
  clearTimeout(undefined);

  const tradeRulesNav = document.querySelectorAll('nav.navbar .nav-item.nav-trade-rules');

  if (tradeRulesNav && tradeRulesNav[0]) {
    FlashesStore.flash(
      FlashesStore.WARNING,
      warningDialog(intl),
    );
    tradeRulesNav[0].classList?.add('trade-rules-highlight');
    tradeRulesNav[0].focus();
    setTimeout(() => {
      tradeRulesNav[0].classList?.remove('trade-rules-highlight');
      FlashesStore.reset();
    }, WARNING_TIMEOUT);
  }
};
