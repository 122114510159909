import { format as d3Format } from 'd3-format';
import React from 'react';

import { TRADE_DIRECTION_UNSPECIFIED } from 'src/util/constants';
import { i18nDecimalFormat } from 'src/util/i18n/handler';
import { EXPORTS, IMPORTS } from '../components/chartConstants';
import { formatCarbonEmission } from './common';

// if the x position is half way through the chart, flip the tooltip to the left
export const getTooltipPosition = (x, chartWidth) => {
  if (chartWidth / x < 2) {
    return x - 230;
  }
  return x;
};

/**
 * Returns the direction label for the tooltip
 * @param {EXPORTS | IMPORTS} dir
 * @param {import('react-intl').IntlShape} intl - i18n react-intl
 * @returns {string} - i18n label
 */
export const getDirectionLabel = (dir, intl) => {
  if (!intl || !dir) {
    return TRADE_DIRECTION_UNSPECIFIED;
  }
  switch (dir) {
    case EXPORTS:
      return intl.formatMessage({ id: 'common.entities.exports.label', defaultMessage: 'Exports' });
    case IMPORTS:
      return intl.formatMessage({ id: 'common.entities.imports.label', defaultMessage: 'Imports' });
    default:
      return TRADE_DIRECTION_UNSPECIFIED;
  }
};
/**
 * Forms the meter label for the tooltip
 * @param {string} header - import or export i18n label
 * @param {object} label -  title and identifier
 * @returns {React.ReactElement} - meter chart tooltip.
 */
export const getMeterChartToolipLabel = (header, label = {}) => {
  const { title, identifier } = label;
  let subHeader = title || '';
  subHeader = identifier ? `${title} (${identifier})` : title;
  return (
    <>
      {header}
      <>
        {subHeader ? ` - ${subHeader}` : ''}
        {' '}
      </>
    </>
  );
};

/**
 * Format the values(carbon/energy) in the tooltip
 * @param {boolean} isCarbon - flag to indicate carbon.
 * @param {string} format - the tooltip format.
 * @param {string} yAxisFormat
 * @param {number} value
 * @param {string} unitLabel
 * @returns {string} - formatted tooltip data.
 */
export const formatTooltipData = (isCarbon, format, yAxisFormat, value, unitLabel) => {
  if (isCarbon) {
    const finalValues = formatCarbonEmission(value);
    if (finalValues) {
      const { value: finalValue, label } = finalValues;
      return `${i18nDecimalFormat(d3Format(format)(finalValue))}${label}`;
    }
  }
  return `${i18nDecimalFormat(d3Format(yAxisFormat)(value))}${unitLabel}`;
};
