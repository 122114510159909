import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const Wrapper = styled.div.attrs({
  className: 'table-responsive',
})``;

const TableElement = styled.table.attrs({
  className: 'table',
})``;

/**
 * Description
 * @param {object} props
 * @returns {React.ReactComponentElement} - tabularData component
 */
function TabularData(props) {
  const { children } = props;

  return (
    <Wrapper>
      <TableElement>
        {children}
      </TableElement>
    </Wrapper>
  );
}

TabularData.propTypes = {
  children: PropTypes.node,
};

TabularData.defaultProps = {
  children: null,
};

export default TabularData;
