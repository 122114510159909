import React from 'react';
import PropTypes from 'prop-types';

import { Alert as AlertReactstrap } from 'reactstrap';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - Alert component
 */
function Alert(props) {
  const { className, color, children } = props;

  return (
    <AlertReactstrap className={className} color={color}>
      {children}
    </AlertReactstrap>
  );
}

Alert.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
};

Alert.defaultProps = {
  className: '',
  color: 'success',
  children: null,
};

export default Alert;
